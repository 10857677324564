import React from "react";
import {getUrls} from "./Config";
import {getCart, getJwt} from "./Helper";
import axios from "axios";
import placentalSerum from "../images/placentalSerumIcon.jpg";
import placentalCream from "../images/placentalCreamIcon.jpg";
import placentalSet from "../images/placentalSetIcon.jpg";
import {NavLink, Redirect} from "react-router-dom";
import {productData} from "./data/ProductData";

class Checkout extends React.Component{
    constructor(props){
        super(props);
        this.state={
            name: '',
            last_name: '',
            email: '',
            phone: '',
            address: '',
            postal_code: '',
            city: '',
            country: '',
            cartItems: {},
            channels: {},
            nameError: '',
            last_nameError: '',
            emailError: '',
            phoneError: '',
            addressError: '',
            postal_codeError: '',
            cityError: '',
            countryError: '',
            orderNr: '',
            nameValid: false,
            last_nameValid: false,
            emailValid: false,
            phoneValid: false,
            addressValid: false,
            postal_codeValid: false,
            cityValid: false,
            countryValid: false,

            formValid: false,

            discountCode: 'PL19FB',
            discountSize: 40,
            discountValue: '',
            loader: true,
            id: false,
            urls: getUrls()
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => this.validate(name));
    };

    validate = (inputName) => {
        let nameErrorMessage = 'Vardo laukas yra privalomas';
        let last_nameErrorMessage = 'Pavardės laukas yra privalomas';
        let emailErrorMessage = 'El. pašto adresas neteisingas';
        let phoneErrorMessage = 'Telefono numerio laukas privalomas';
        let addressErrorMessage = 'Addreso laukas privalomas';
        let postal_codeErrorMessage = 'Pašto kodo laukas privalomas';
        let cityErrorMessage = 'Miesto laukas privalomas';
        let countryErrorMessage = 'Šalies laukas privalomas';
        let ErrorMessage = eval(inputName + 'ErrorMessage');
        let error = inputName + 'Error';
        let valid = inputName + 'Valid';

        if(inputName in this.state) {
            if(inputName === 'email'){
                this.state[inputName] && this.state.email.includes('@') ? this.setState({
                    [error]: '',
                    [valid]: true
                },() => this.allowPayment()) : this.setState({
                    [error]: ErrorMessage,
                    [valid]: false
                }, () => this.allowPayment());
            } else {
                this.state[inputName] ? this.setState({
                    [error]: '',
                    [valid]: true
                }, () => this.allowPayment()) : this.setState({
                    [error]: ErrorMessage,
                    [valid]: false
                }, () => this.allowPayment());
            }
        }
        this.setAnonymousUserDetails(inputName)
    };

    allowPayment = () => {
        let result = [];

        for (let prop in this.state) {
            if (Object.prototype.hasOwnProperty.call(this.state, prop)) {
                if(prop.includes('Valid') && !prop.includes('form')){
                    result.push(this.state[prop])
                }
            }
        }

        if(this.calculatePrice(this.props.price, this.props.cart) > 0){
            this.setState({
                formValid: !result.includes(false)
            }
            );
        }
    };

    calculateInitPrice = () => {
        let items = this.getCartItems();
        let total = 0;

        if(items){
           total = items.cream * productData[0]["cream"].price +
               items.serum * productData[0]["serum"].price +
               items.set * productData[0]["set"].price;

            if (localStorage.getItem('discount') !== null){
                let totalAfterDiscount = parseFloat(total);
                total = totalAfterDiscount - totalAfterDiscount * .25;
            }
        }
        return total;
    };

    calculatePrice = () => {
        let items = this.getCartItems();
        let total = 0;

        if(items){
            total = items.cream * productData[0]["cream"].price +
                items.serum * productData[0]["serum"].price +
                items.set * productData[0]["set"].price;
        }
        return total;
    };

    validateAgainstZero = (price, amount) => {
        let cost = (price * amount) - this.calculateDiscount(price, amount);

        return !(cost < 0 || cost === 0);
    };

    calculateDiscount = () => {
        let total = this.calculatePrice();

            if (localStorage.getItem('discount') !== null){
                let totalAfterDiscount = parseFloat(total);
                total = totalAfterDiscount * .25;
            }else{
                return 0;
            }

        return total;
    };

    getPaymentChannels = () => {
        axios.get(this.state.urls.environment.development + this.state.urls.apiVersion + this.state.urls.opay.paymentChannels).then(
            (res) => {
                let channels = {...this.state.channels};
                setTimeout(function(){
                    this.setState({
                        channels: res.data.response.result.banklink.channels,
                        loader: false
                    });
                }.bind(this), 2000);
            }
        ).catch(
            (err) => {
                console.log(err);
            }
        );
    };

    setAnonymousUserDetails = (name) => {
        let anonymousUserObject = JSON.parse(localStorage.getItem("anonymous-user"));
        if (anonymousUserObject === null) {
            anonymousUserObject = {
                'name': this.state.name,
                'last_name': this.state.last_name,
                'email': this.state.email,
                'phone': this.state.phone,
                'address': this.state.address,
                'postal_code': this.state.postal_code,
                'city': this.state.city,
                'country': this.state.country,
                'id': ""
            };
            localStorage.setItem('anonymous-user', JSON.stringify(anonymousUserObject));
        }

        anonymousUserObject[name] = this.state[name];
        localStorage.setItem('anonymous-user', JSON.stringify(anonymousUserObject));
    };

    getUserDetails = () => {
        let jwt = getJwt();
        axios.get(this.state.urls.environment.development + this.state.urls.apiVersion + this.state.urls.user.profile, {
            'headers': { 'Authorization': jwt }
        }).then(
            (res) => {
                this.setState({
                    id: res.data.id,
                    email: res.data.email,
                    name: res.data.name,
                    last_name: res.data.lastName,
                    phone: res.data.phone,
                    country: res.data.country,
                    city: res.data.city,
                    address: res.data.street,
                    postal_code: res.data.postalCode
                });
            }
        ).catch(
            (err) => {
                console.log(err);
            }
        );
    }

    getCartItems = () => {
        return JSON.parse(
            localStorage.getItem(
                "cartItems"
            )
        );
    }

    setCartItemsToState = () => {
        let cartItems = this.getCartItems();

        this.setState({
            cartItems: cartItems
        });
    }

    setUserDetailsToState = () => {
        this.getUserDetails();

        if (this.state.id === false){

            let anonymousUserObject = JSON.parse(
                localStorage.getItem(
                    "anonymous-user")
            );

            if (anonymousUserObject != null) {
                for (var key of Object.keys(anonymousUserObject)) {
                    let fieldName = key;

                    this.setState({
                        [key]: anonymousUserObject[key]
                    }, () => {
                        if(fieldName !== 'id'){
                            this.validate(fieldName);
                        }
                    });
                }
            }
        }
    };

    componentDidMount(){
        this.setUserDetailsToState();
        this.getPaymentChannels();
        this.setCartItemsToState();
    }

    render() {
        if (localStorage.getItem("username") !== null) {
            return <Redirect to={{
                pathname: '/payment'
            }} />
        }
        return(
            <div className={"content"}>
                <div className="row p-4">
                    <div className="col-12">
                        <h2 className={'font-weight-light mb-4'}>Apmokėjimas</h2>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className="col-md-8">
                        <h5 className={'font-weight-light'}>Pristatymo detalės</h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                        <small>Vardas </small>
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="email"
                                           name={'name'}
                                           onChange={this.handleChange}
                                           className={'text-sm form-control form-control-lg ' +
                                               (this.state.nameError ? 'is-invalid'  : '') +
                                               (this.state.nameValid ? 'is-valid' : '')}
                                           id="exampleInputEmail1"
                                           aria-describedby="emailHelp"
                                           placeholder="Vardas" value={this.state.name} />
                                    <small className={'text-danger'}>{this.state.nameError}</small>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><small>Pavardė</small></label>
                                    <span className="text-danger"> *</span>
                                    <input type="text"
                                           name={'last_name'}
                                           onChange={this.handleChange}
                                           className={'text-sm form-control form-control-lg ' +
                                               (this.state.last_nameError ? 'is-invalid'  : '') +
                                               (this.state.last_nameValid ? 'is-valid' : '')}
                                           aria-describedby="emailHelp"
                                           placeholder="Pavardė"
                                           value={this.state.last_name}/>
                                    <small className={'text-danger'}>{this.state.last_nameError}</small>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><small>El. pašto adresas</small></label>
                                    <span className="text-danger"> *</span>
                                    <input type="email" name={'email'}
                                           onChange={this.handleChange}
                                           className={'text-sm form-control form-control-lg ' +
                                               (this.state.emailError ? 'is-invalid'  : '') +
                                               (this.state.emailValid ? 'is-valid' : '')}
                                           placeholder="El. pašto adresas"
                                           value={this.state.email} />
                                    <small className={'text-danger'}>{this.state.emailError}</small>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                        <small>Telefono numeris</small>
                                    </label>
                                    <span className="text-danger"> *</span>
                                    <input type="text"
                                           name={'phone'}
                                           onChange={this.handleChange}
                                           className={'form-control form-control-lg '  + (this.state.phoneError ? 'is-invalid'  : '') + (this.state.phoneValid ? 'is-valid' : '')}
                                           placeholder="Telefono numeris"
                                           value={this.state.phone} />
                                    <small className={'text-danger'}>{this.state.phoneError}</small>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-8">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                        <small>Adresas</small>
                                    </label>
                                    <span className="text-danger"> *</span>
                                    <input type="text"
                                           name={'address'}
                                           onChange={this.handleChange}
                                           className={'form-control form-control-lg '  + (this.state.addressError ?
                                               'is-invalid'  :
                                               '') +
                                               (this.state.addressValid ?
                                                   'is-valid' :
                                                   '')}
                                           placeholder="Adresas"
                                           value={this.state.address} />

                                    <small className={'text-danger'}>{this.state.addressError}</small>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><small>Pašto kodas</small></label>
                                    <span className="text-danger"> *</span>

                                    <input type="text"
                                           name={'postal_code'}
                                           onChange={this.handleChange}
                                           className={'form-control form-control-lg '  + (this.state.postal_codeError ?
                                               'is-invalid'  :
                                               '') +
                                               (this.state.postal_codeValid ?
                                                   'is-valid' :
                                                   '')}
                                           placeholder="Pašto kodas"
                                           value={this.state.postal_code}  />

                                    <small className={'text-danger'}>{this.state.postal_codeError}</small>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                        <small>Miestas</small>
                                    </label>
                                    <span className="text-danger">*</span>

                                    <input type="text"
                                           name={'city'}
                                           onChange={this.handleChange}
                                           className={'form-control form-control-lg '  + (this.state.cityError ?
                                               'is-invalid'  : '') + (this.state.cityValid ? 'is-valid' : '')}
                                           placeholder="Miestas"
                                           value={this.state.city}  />

                                    <small className={'text-danger'}>{this.state.cityError}</small>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                        <small>Šalis</small>
                                    </label>
                                    <span className="text-danger"> *</span>
                                    <input type="text"
                                           name={'country'}
                                           onChange={this.handleChange}
                                           className={'form-control form-control-lg '  + (this.state.countryError ?
                                               'is-invalid'  : '') + (this.state.countryValid ? 'is-valid' : '')}
                                           placeholder="Šalis"
                                           value={this.state.country}
                                    />
                                    <small className={'text-danger'}>
                                        {this.state.countryError}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4 mb-4">
                            <div className="col-12">
                                <h4 className={'font-weight-light'}>Apmokėjimo kanalai</h4>
                            </div>
                        </div>

                        <div className="row mt-2 mb-3">
                            <div className="col-12 text-center">
                                { this.state.loader ?
                                    <span>
                                        <fa className="fas fa-sync fa-spin"></fa> &nbsp;
                                        <small>Prašome palaukti ....</small>
                                    </span>
                                    :
                                    <div className="alert alert-primary text-left"
                                         role="alert">
                                        <span className={'fa fa-info-circle fa-lg d-inline-block mr-2'}></span>
                                        <small>Mokėjimo kanalą galėsite pasirinkti kitame žingsnyje.</small>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={'row mb-3'}>
                            {Object.keys(this.state.channels).map((key) => {
                                return <div className={'col'}>
                                    <img className={'mt-3 mb-4'}
                                         src={this.state.channels[key].logo_urls.color_49px}
                                    />
                                </div>
                            })
                            }
                        </div>
                    </div>

                    <div className="col-md-4 pl-3 order-summary">
                        <h5 className={'font-weight-light mb-4'}>Užsakymo suvestinė</h5>
                        <div className={'shadow p-4'}>
                            <div className="row">

                                    {
                                        Object.entries(this.state.cartItems).map((key) => {
                                            let product = {};
                                            if(key[1] > 0 && key[0] !== "count"){
                                                product = {
                                                    "count": key[1],
                                                    "price": productData[0][key[0]].price
                                                }
                                            }else{
                                                return null;
                                            }
                                            return <div className={"col-12 mb-3"}>
                                                <img className={key[0] === 'cream' ?
                                                    'mr-3 mt-2 mb-2 float-left' :
                                                    "d-none"}
                                                     src={placentalCream}
                                                     alt={'Placental cart item'}
                                                     width={'64px'}
                                                     height={'64px'}
                                                />

                                                <img className={key[0] === 'serum' ?
                                                    'mr-3 mt-2 mb-2 float-left' :
                                                    "d-none"}
                                                     src={placentalSerum}
                                                     alt={'Placental cart item'}
                                                     width={'64px'}
                                                     height={'64px'}
                                                />
                                                <img className={key[0] === 'set' ?
                                                    'mr-3 mt-2 mb-2 float-left' :
                                                    "d-none"}
                                                     src={placentalSet}
                                                     alt={'Placental cart item'}
                                                     width={'64px'}
                                                     height={'64px'}
                                                />
                                                <small className={"d-block pt-2"}>
                                                    <small className={"text-secondary pr-2"}>Vnt</small>
                                                    {product.count}
                                                </small>
                                                <small className={"d-block pt-1"}>
                                                    <small className={"text-secondary pr-2"}>Kaina</small>
                                                    {product.price}
                                                </small>
                                                <small className={"d-block pt-1"}>
                                                    <small className={"text-secondary pr-2"}>Suma</small>
                                                    {product.price * product.count}€
                                                </small>
                                            </div>
                                        })
                                    }
                            </div>
                            <hr />
                            <div className="row mt-4 pt-4">
                                <div className="col-7"><p>Tarpinė suma</p></div>

                                <div className="col-5 text-right">
                                    <p>{this.calculateInitPrice().toFixed(2)} €</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6"><p>Nuolaida</p></div>
                                <div className="col-6 text-right">
                                    <p>{this.calculateDiscount(this.props.price, this.props.cart).toFixed(2)} €</p>
                                </div>
                            </div>

                            <div className="row pb-2">
                                <div className="col-6">
                                    <p>Pristatymas</p>
                                </div>
                                <div className="col-6 text-right">
                                    <p>0 €</p>
                                </div>
                            </div>

                            <hr/>

                            <div className="row pt-4">
                                <div className="col-6">
                                    <p>Viso</p>
                                </div>
                                <div className="col-6 text-right">
                                    <h5 className={'font-weight-bold'}>
                                        {this.calculateInitPrice().toFixed(2)} €
                                    </h5>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 text-right mt-3">
                                    <NavLink
                                        className={this.state.formValid ?
                                            'btn btn-primary btn-lg mt-4' :
                                            "btn btn-primary btn-lg mt-4 disabled"}
                                        to={'/payment'}>
                                        Apmokėti
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Checkout