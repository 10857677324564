export const getUrls = () => {
    return {
        environment:{
            development: 'http://127.0.0.1:8080'
            // development: 'https://d9rnb4peo8bk4.cloudfront.net'
        },
        apiVersion: '/api/v1',
        checkout:{
            get: '/checkout'
        },
        order: {
            get: "/orders",
            post: '/orders/',
            patch: '/orders/',
            delete: '/orders/',
            paginate: '/orders/paginate',
            product: '/orders/product',
            order: '/orders/order/',
            generateOrderNumber: '/orders/generate-order-number'
        },
        user: {
            get: "/users",
            patch: "/users/",
            orders: "/users/orders/",
            info: "/users/user",
            profile: "/users/profile",
            validateJWT: "/users/validate-jwt",
            register: "/users/register",
            validateUserData: "/users/validate?username=",
            updatePassword: "/users/update-password",
        },
        opay: {
            paymentChannels: "/opay/payment-channels",
            checkout: "/opay/make-payment",
            paymentDone: "/opay/?encoded=",
            checkPayment: "/opay/check-payment/?orderNr="
        },
        confirm: "/api/v1/users/confirm?token=",
        passwordReset: "/api/v1/users/reset",
        passwordResetConfirmation: "/api/v1/users/reset?token="

    };
}