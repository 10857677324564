import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'line-awesome/dist/font-awesome-line-awesome/css/all.min.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'izitoast/dist/css/iziToast.min.css';
import './index.css';

import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/popper.min';
import 'bootstrap/dist/js/bootstrap.min.js';
import iziToast from 'izitoast/dist/js/iziToast.min';

import Login from './components/Login';
import Delivery from './components/Delivery';
import Contact from './components/Contact';
import {getJwt} from './components/Helper';
import PageNotFound from './components/PageNotFound';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Payment from './components/Payment';
import PrivateRoute from './components/PrivateRoute'
import Header from './components/Header'
import ConfirmRegistration from './components/ConfirmRegistration'
import PasswordReset from './components/PasswordReset'
import {getUrls} from './components/Config';
import UserProfile from "./components/user/UserProfile";
import UserOrders from "./components/user/UserOrders";
import AdminOrders from "./components/admin/AdminOrders";
import ImportantToKnowPage from "./components/pages/ImportantToKnowPage";
import FAQ from "./components/pages/FAQ";
import AboutChanges from "./components/pages/AboutChanges";
import Wholesale from "./components/pages/Wholesale";
import Help from "./components/pages/Help";
import Black from "./components/pages/Black";
import Payments from "./components/pages/Payments";
import ReturnGoods from "./components/pages/ReturnGoods";
import Certificates from "./components/pages/Certificates";
import CompanyDetails from "./components/pages/CompanyDetails";
import BioProteinolPf from "./components/pages/BioProteinolPf";
import CreamComposition from "./components/pages/CreamComposition";
import SerumComposition from "./components/pages/SerumComposition";
import Cart from "./components/Cart";
import Home from "./components/Home";
import PaymentAccepted from "./components/PaymentAccepted";
import Register from "./components/user/Register";
import { BrowserRouter as Router, Route, Switch, NavLink, withRouter } from "react-router-dom";
import axios from "axios";
import Checkout from "./components/Checkout";
import PasswordResetConfirm from "./components/user/PasswordResetConfirm";
import TopMenu from "./components/helpers/TopMenu";

import CartContextProvider, {AppContext} from "./components/context/AppContext";


class CartIcon extends React.Component {
    constructor(props){
        super(props);
        this.state={
            username: false,
            loggedIn: false,
            role: false
        };
    };

    logout = () => {
        localStorage.clear();
        this.props.history.push('/Login');
        this.props.clearCart();
    };

    authenticate = () => {
        let username = localStorage.getItem("username");
        let role = localStorage.getItem("role");
        if (username != null) {
            this.setState({username: username, loggedIn: true});
            if (role != null) {
                this.setState({role: role});
            }
        }else{
            this.setState({
                username: false,
                loggedIn: false, role: false
            });
        }
    };

    componentDidMount() {
        this.authenticate();
        this.props.history.listen((location, action) => {

            setTimeout(() => {
               this.authenticate();
            }, 200);
        });
    }

    render() {
        return (
            <AppContext.Consumer>{(context) => {
                return(
            <div className={"row"}>
                <div className={"col-12"}>
                    <ul className={"list-group list-group-horizonta d-none d-md-block"}>
                        {(this.state.role) &&
                            <li className={"list-inline-item"}>
                                <span className={'fa fa-folder fa-lg '}/>
                                <NavLink to={'/admin'} className={'mr-3 plc-user-menu-text pl-1'}>Admininstravimas</NavLink>
                            </li>
                        }
                        <i className="fa-thin fa-screwdriver-wrench"></i>
                        {(this.state.loggedIn) &&
                        (this.state.role !== "ROLE_ADMIN") &&
                            <li className={"list-inline-item"}>
                                <span className={'fa fa-shopping-cart fa-lg'}/>
                                <NavLink to={'/orders'} className={'mr-4 plc-user-menu-text pl-1'}>Užsakymai</NavLink>
                            </li>
                        }

                        {(this.state.loggedIn) &&
                            <span>
                        <li className={"list-inline-item"}>
                            <span className={'fa fa-cog fa-lg'}/>
                            <NavLink to={'/profile'} className={'mr-5 plc-user-menu-text pl-1'}>Vartotojo zona</NavLink>
                        </li>
                        <li className={"list-inline-item"}>
                            <div className="btn-group mr-4">
                                <button className="btn btn-sm btn-sm dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                    <span className={'fa fa-user fa-lg'}/>
                                    <div className={'d-inline-block mr-2 ml-2'}>{ this.state.username }</div>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right user-zone">
                                    <button onClick={this.logout}
                                            className={'btn mr-3 btn-link'}>
                                        <span className={'fa fa-sign-out-alt fa-lg'}/>
                                        &nbsp;Atsijungti</button>
                                </div>
                            </div>
                        </li>
                                </span>
                        }

                        { (!this.state.loggedIn) &&
                        <li className={"list-inline-item"}>
                            <NavLink to={'/login'} className={'mr-3'}>Prisijungti</NavLink>
                        </li>
                        }

                        { (!this.state.loggedIn) &&
                        <li className={"list-inline-item"}>
                            <NavLink to={'/register'} className={'mr-3'}>Registruotis</NavLink>
                        </li>
                        }

                    <li className={'cart-container list-inline-item'}>
                        <NavLink to={'/cart'}>
                            <i className={"fa fa-shopping-cart cart-size text-muted d-inline-block"}></i>
                            <small className={"cart-counter"}>{context.cartItemsCount}</small>
                        </NavLink>
                    </li>

                    </ul>

                    <ul className={"list-group list-group-horizonta d-block d-md-none"}>
                        <li className={'cart-container list-inline-item'}>
                            <NavLink to={'/cart'}>
                                <i className={"fa fa-shopping-cart cart-size text-muted d-inline-block"}></i>
                                <small className={"cart-counter"}>{context.cartItemsCount}</small>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
                );
            }
            }
            </AppContext.Consumer>
        )
    }
}

export const CartIconConst = withRouter(CartIcon);


class Shop extends React.Component {
    constructor(props){
        super(props);
        this.state={
            cart: null,
            initPrice: 120,
            price: 120,
            email: null,
            urls: getUrls(),
            proceed_checkout_class: {
                init: false,
                active: false
            },
            discountSize: 30
        };
        this.handleDiscount = this.handleDiscount.bind(this)
        this.setUserDetails = this.setUserDetails.bind(this);
        this.addToCart = this.addToCart.bind(this);
    }

    handleDiscount = () => {
        localStorage.setItem(
            'discount',
            'true'
        );
    }

    revertDiscount = () => {
        localStorage.removeItem(
            'discount'
        );
    }

    infoMessageToast = (text) => {
        iziToast.show({
            id: null,
            class: '',
            title: '',
            titleColor: '',
            titleSize: '',
            titleLineHeight: '',
            message: 'Placental Face Cream ' + text,
            messageColor: '#00c9a7',
            messageSize: '12',
            messageLineHeight: '',
            backgroundColor: '',
            theme: '', // dark, light
            color: '#E9FAF6', // blue, red, green, yellow
            icon: 'fa fa-cart-arrow-down',
            iconText: '',
            iconColor: '#00c9a7',
            iconUrl: null,
            image: '',
            imageWidth: 50,
            maxWidth: null,
            zindex: null,
            layout: 1,
            balloon: false,
            close: true,
            closeOnEscape: false,
            closeOnClick: false,
            displayMode: 0, // once, replace
            position: 'topLeft', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            target: '',
            targetFirst: true,
            timeout: 3000,
            rtl: false,
            animateInside: true,
            drag: true,
            pauseOnHover: true,
            resetOnHover: false,
            progressBar: false,
            progressBarColor: '#00c9a7',
            progressBarEasing: 'linear',
            overlay: false,
            overlayClose: false,
            overlayColor: 'rgba(0, 0, 0, 0.6)',
            transitionIn: 'fadeInUp',
            transitionOut: 'fadeOut',
            transitionInMobile: 'fadeInUp',
            transitionOutMobile: 'fadeOutDown',
            buttons: {},
            inputs: {},
            onOpening: function () {},
            onOpened: function () {},
            onClosing: function () {},
            onClosed: function () {}
        });

    };

    addToCart = (item, add = true) => {
        let increaseNumber = 1;
        let itemCountKey = "count";
        let itemsCount = 0;
        let message = "pridėtas į krepšelį.";

        let cartItemsStorageObject = localStorage.getItem(
            'cartItems'
        );
        let cartItemsStorageObjectToJson = JSON.parse(
            cartItemsStorageObject
        );

        let cartItemsObject  = {
            "cream": 0,
            "serum": 0,
            "set": 0,
            "count": 0
        }

        if (cartItemsStorageObject !== null && cartItemsStorageObject !== "NaN") {
            cartItemsObject = cartItemsStorageObjectToJson;
        }

        if (!add && cartItemsObject[item] !== 0){
            increaseNumber = -1;
            message = "išimtas iš krepšelio.";
        }

        if (!add && cartItemsObject[item] === 0){
            increaseNumber = 0;
        }

        cartItemsObject[item] = cartItemsObject[item] + increaseNumber;

        Object.entries(cartItemsObject).forEach(item => {
            if (item[0] !== itemCountKey){
                itemsCount += item[1];
            }
        });

        cartItemsObject[itemCountKey] = itemsCount

        localStorage.setItem(
            'cartItems',
            JSON.stringify(cartItemsObject)
        );

        if(this.getCartCount() === 0){
            localStorage.removeItem(
                "cartItems"
            );
        }

        this.setState({
                cart: JSON.stringify(cartItemsObject)
            }
        );

        this.infoMessageToast(message);
    };

    getCartCount = () => {
        let cartItems = localStorage.getItem("cartItems");

        if (cartItems === null || cartItems === "NaN"){
            return 0;
        }

        return JSON.parse(cartItems).count;
    }

    componentDidMount(){
        localStorage.setItem("lang", "lt");

        let items = localStorage.getItem('cartItems');

        if(items){
            this.setState({
                cart: items
            });
        }
    }

    setUserDetails = () => {
        const jwt = getJwt();

        axios.get(
            this.state.urls.environment.development +
            this.state.urls.apiVersion +
            this.state.urls.user.info, {
            headers: { Authorization: `${jwt}` }
        }).then(
            (res) => {
                this.setState({
                    username: res.data.principal
                });

                localStorage.setItem(
                    'username',
                    res.data.principal
                );

                let role = res.data.authorities[0].authority;

                if(role === "ROLE_ADMIN"){
                    localStorage.setItem(
                        'role', role
                    );
                }
            }
        ).catch(
            (err) => {
                localStorage.removeItem('jwt-access')
                console.log(err);
            }
        );
    };

    clearCart = async () => {
        this.setState({
            cart: null
        });
    };
    static contextType = AppContext;
    render() {
        return (
            <CartContextProvider>
                <Router>
                    <ScrollToTop>
                            <TopMenu/>
                            <Header cart={
                                        this.getCartCount()
                                    }
                                    username={ () => this.state.username() }
                                    proceed_checkout_class={ this.state.proceed_checkout_class }
                                    add_to_cart = {
                                        (item) => this.addToCart(item)
                                    }
                                    clearCart = { () => this.clearCart()}
                                    loggedIn = { () => this.state.loggedIn() }
                                    role = { () => this.state.role() }
                            />

                            <Switch>

                                <Route path={'/'}
                                       render={(props) => <Home {...props}
                                                                      add_to_cart = { (item) => this.addToCart(item)}
                                       />}
                                       exact />

                                <Route path={'/cart'}
                                       render={(props) => <Cart {...props}
                                                                handleDiscount={() =>  this.handleDiscount()}
                                                                revertDiscount={() =>  this.revertDiscount()}
                                                                cart={this.state.cart}
                                                                price={this.state.price}
                                                                add_to_cart = {
                                           (item) => this.addToCart(item, true)
                                       }
                                                                remove_from_cart = { (item) => this.addToCart(item, false)}
                                       />} exact />
                                <Route path={'/login'} render={(props) => <Login  {...props}
                                                                                setUser = { () => this.setUserDetails() }
                                />} exact />
                                <Route path={'/register'} component={Register} exact />

                                <Route path={'/checkout'} render={(props) => <Checkout  {...props}
                                                                                   cart={this.state.cart}
                                                                                   price={this.state.price}
                                />} exact />
                                <Route path={'/delivery'} component={Delivery} exact />
                                <Route path={'/company-details'} component={CompanyDetails} exact />
                                <Route path={'/payment-accepted/:encoded'} component={PaymentAccepted} exact />
                                <Route path={'/confirm'} component={ConfirmRegistration} exact />
                                <Route path={'/password-reset'} component={PasswordReset} exact />
                                <Route path={'/contacts'} component={Contact} exact />
                                <Route path={'/admin'} component={AdminOrders} exact />
                                <Route path={'/important-to-know'} component={ImportantToKnowPage} exact />
                                <Route path={'/bio-proteinol-pf'} component={BioProteinolPf} exact />
                                <Route path={'/faq'} component={FAQ} exact />
                                <Route path={'/certificates'} component={Certificates} exact />
                                <Route path={'/help'} component={Help} exact />
                                <Route path={'/changes'} component={AboutChanges} exact />
                                <Route path={'/password-reset-confirmation'} component={PasswordResetConfirm} exact />
                                <Route path={'/wholesale'} component={Wholesale} exact />
                                <Route path={'/return-goods'} component={ReturnGoods} exact />
                                <Route path={'/black'} component={Black} exact />
                                <Route path={'/cream-composition'} component={CreamComposition} exact />
                                <Route path={'/serum-composition'} component={SerumComposition} exact />
                                <Route path={'/payments'} component={Payments} exact />
                                <PrivateRoute path={'/orders'} component={UserOrders} exact />
                                <PrivateRoute path={'/profile'} component={UserProfile} exact />
                                <Route path={'/payment'}
                                       render={(props) => <Payment  {...props}
                                                                    cart={this.state.cart}
                                                                    price={this.state.price}
                                                                    clearCart = { () => this.clearCart()}
                                />} exact />
                                <Route component={PageNotFound} />

                            </Switch>
                            <Footer/>
                    </ScrollToTop>
                </Router>
            </CartContextProvider>
        )
    }
}


ReactDOM.render(<Shop />, document.getElementById("root"));