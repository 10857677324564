import React from "react";
import {getUrls} from "./Config";
import Request from "axios-request-handler";

class ProceedPayment extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            paymentReceived: false,
            orderNr: null,
            urls: getUrls(),
            limit: false
        }
    }

    cleanUpOrderDetails = () => {
        localStorage.removeItem('orderInitiated');
        localStorage.removeItem('anonymous-user');
        localStorage.setItem("paymentInitiated", 'false')
        localStorage.removeItem('orderNr');
        localStorage.setItem(
            "cartItems", JSON.stringify(
            {
                "cream":0,"serum":0,"set":0, "count":0
            })
        );
        this.props.clearCart()
    }

    statusCheckPoll = () => {
        if (this.state.orderNr !== null){
            const order = new Request(
                this.state.urls.environment.development +
                this.state.urls.apiVersion +
                this.state.urls.opay.checkPayment +
                this.state.orderNr
            );
            let limit = 0;
            order.poll(5000).get((response) => {
                limit++;
                if(limit > 100){
                    this.cleanUpOrderDetails();
                    this.setState({limit: true})
                    return false;
                }

                if(response.data){
                    this.cleanUpOrderDetails();
                    this.setState({
                        paymentReceived: true
                    })
                    return false;
                }
            });
        }
    }

    checkPaymentMethod = () => {
        let paymentMethod = localStorage.getItem("paymentMethod");
        if(paymentMethod !== "INTEGRATED"){
            this.setState({
                paymentReceived: true
            })
            this.cleanUpOrderDetails();
        }
    }

    componentDidMount(){
        this.checkPaymentMethod();
        this.setState({
            orderNr: this.props.orderNr
        }, () => {
            this.statusCheckPoll();
        });
    }

    render() {
        return (
            <div className={"content"}>
                <div className="row">
                    <div className="col-12">
                        <div className={(this.state.limit === false ? '' : 'd-none')}>
                            <h1 className="font-weight-light mb-5 mt-4 text-dark">Vykdoma apmokėjimo procedūra</h1>
                            <fa className="fa fa-lg fa-sync fa-spin"></fa>
                            <p style={{"font-size": "16px"}}
                               className={'d-inline-block mr-3'}>
                                &nbsp; Prašome palaukti, laukiame mokėjimo patvirtinimo, užsakymui, kurio numeris
                                #{this.props.orderNr}
                            </p>

                            <p className={'ml-4 d-block mt-3'}>Norėdami matyti visą pirkimų istoriją, maloniai prašome
                                užsiregistruoti pas mus sistemoje.</p>
                        </div>

                        <div className={(this.state.limit === true ? '' : 'd-none')}>
                            <h1 className="font-weight-light mb-5 mt-4 text-dark">Mokėjimo patvirtinimo negavome</h1>
                            <p className={"alert alert-primary"}>Mokėjimas dar nepsiekė mūsų, vos tik gausime mokėjimo
                                patvirtinimą iš banko informuosime Jus atskiru el. laišku. Jūsų užsakymo statusas
                                "Laukiama mokėjimo".</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProceedPayment