import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import {CartIconConst} from "../index";
import {AppContext} from "./context/AppContext";

class Header extends React.Component{
    constructor(props){
        super(props);
        this.state={
            showMobileMenu: false
        };
    };

    showHideMobileMenu = () => {
        let currentState = this.state.showMobileMenu;

        this.setState({
            showMobileMenu: !currentState
        });
    };

    logout = () => {
        localStorage.clear();
        this.props.history.push('/Login');
        this.props.clearCart();
    };

    render() {
        return (
            <AppContext.Consumer>{(context) => {
                return(
                    <div className={'header'}>
                        <div className={'row pt-4 pl-4 pr-4 pb-'}>
                            <div className={"col-5"}>
                                <span className="flag-icon fl flag-icon-lt d-inline-block mr-2"
                                      style={{fontSize: '12px'}}
                                />
                            </div>
                            <div className={'col-7 text-right'}>
                                <CartIconConst
                                    items={ this.props.cart  }
                                    checkout={ this.props.proceed_checkout_class }
                                    user={ this.props.username }
                                    email={ this.props.email }
                                    clearCart = { () => this.props.clearCart() }
                                />
                            </div>
                        </div>

                        <div className={'row p-4'}>
                            <div className={'col-4'}>
                                <NavLink className={"text-dark text-decoration-none"} to={'/'}>
                                    <h1 className={"placental-title"}>Placental</h1>
                                </NavLink>
                            </div>
                            <div className={'col-8 text-right mt-4'}>
                        <span role="button" onClick={this.showHideMobileMenu}
                              className={'mt-0 fa fa-bars fa-2x d-inline-block d-md-none '}/>

                                <ul className={this.state.showMobileMenu ?
                                    'main-menu-mobile d-block d-md-none list-unstyled' :
                                    'main-menu d-none d-md-block list-unstyled'}>

                                    <li className={'pr-3 pl-3'}>
                                        <NavLink to={'/cart'}>Krepšelis</NavLink>
                                    </li>
                                    <li className={'pr-3 pl-3'}>
                                        <NavLink to={'/cream-composition'}>Kremas</NavLink>
                                    </li>
                                    <li className={'pr-3 pl-3'}>
                                        <NavLink to={'/serum-composition'}>Serumas</NavLink>
                                    </li>
                                    <li className={'pr-3 pl-3'}>
                                        <NavLink to={'/contacts'}>Kontaktai</NavLink>
                                    </li>
                                    <li className={'pr-3 pl-3'}>
                                        <NavLink to={'/FAQ'}>DUK</NavLink>
                                    </li>
                                    <li className={'pr-3 pl-3'}>
                                        <NavLink to={'/help'}>Pagalba</NavLink>
                                    </li>

                                    {(localStorage.getItem("username") != null) &&
                                        (localStorage.getItem("role") !== "ROLE_ADMIN") &&
                                        <span className={this.state.showMobileMenu ?
                                            'd-block list-unstyled' :
                                            'main-menu d-none list-unstyled'}>
                                    <li className={"pr-3 pl-3"}>
                                        <span className={'fa fa-shopping-cart fa-lg'}/>
                                        <NavLink className={"pl-1"} to={'/orders'} >Užsakymai</NavLink>
                                    </li>
                                </span>
                                    }
                                    {(localStorage.getItem("username") != null) &&
                                        <li className={this.state.showMobileMenu ?
                                            'd-block list-unstyled' :
                                            'main-menu d-none list-unstyled'}>
                                            <span className={'fa fa-cog fa-lg'}/>
                                            <NavLink className={"pl-1"}
                                                     to={'/profile'}>Vartotojo zona</NavLink>
                                        </li>
                                    }
                                    { (localStorage.getItem("username") != null) &&
                                        (localStorage.getItem("role") === "ROLE_ADMIN") &&
                                        <li className={this.state.showMobileMenu ?
                                            'main-menu-mobile d-block list-unstyled mt-0' :
                                            'd-none'}>
                                            <span className={'fa fa-folder fa-lg '}/>
                                            <NavLink className={"pl-1"}
                                                     to={'/admin'} >Administravimas</NavLink>
                                        </li>
                                    }
                                    {(localStorage.getItem("username") != null) &&
                                        <span className={this.state.showMobileMenu ?
                                            'main-menu-mobile d-block list-unstyled' :
                                            'main-menu d-none list-unstyled'}>

                                            <li className={"list-inline-item"}>
                                                <span className={'fa fa-user fa-lg '}/>
                                                <a href="#"
                                                   className={"pl-1"}>{ localStorage.getItem("username") }</a>
                                            </li>
                                            <li>
                                                <span className={'fa fa-sign-out-alt fa-lg'}/>
                                                <a href=""
                                                   className={"pl-1"}
                                                   onClick={this.logout}>Atsijungti</a>
                                            </li>
                                        </span>
                                    }
                                    {(localStorage.getItem("username") === null) &&
                                        <li className={this.state.showMobileMenu ? 'd-block pr-3 pl-3' : 'd-none'}>
                                            <NavLink to={'/login'} className={'pl-1'}>Prisijungti</NavLink>
                                        </li>
                                    }

                                    {(localStorage.getItem("username") === null) &&
                                        <li className={this.state.showMobileMenu ? 'd-block pr-3 pl-3' : 'd-none'}>
                                            <NavLink to={'/register'} className={'pl-1'}>Registruotis</NavLink>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                );
            }}
            </AppContext.Consumer>
        )
    }
}

export default withRouter(Header)