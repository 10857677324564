import React from "react";
import CheckoutSuccess from "./CheckoutSuccess";
import CheckoutProgress from "./CheckoutProgress";
import CheckoutError from "./CheckoutError";

class CheckoutProcess extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            progress: true,
            error: false,
            success: false
        }
    }

    render() {
        return (
            <div className={"content"}>
                <div className={'row pl-3 pr-3'}>
                    <div className={"col-12 text-center"}>

                        {this.props.progress && (
                            <CheckoutProgress />
                        )}

                        {this.props.success && (
                            <CheckoutSuccess clearCart = { () => this.props.clearCart()} />
                        )}

                        {this.props.error && (
                            <CheckoutError />
                        )}

                    </div>
                </div>
            </div>
        )
    }
}

export default CheckoutProcess