import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class CompanyGoods extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'} to={'/'}>Titulinis</NavLink>
                                <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'} to={'/company-details'}>Rekvizitai</NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-sm-12'}>
                        <h3 className="font-weight-light mb-2">Rekvizitai</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Mūsų rekvizitai</h4>
                        <h6 className="font-weight-light" style={{"line-height":"1.6"}}>
                            Pateikiame mūsų rekvizitus,
                            kuriuos galite naudot mokėjimams, korespondencijai ir bet kokiai kitai situacijai susijusiai
                            su Placental kremu.</h6>
                        <h6 className="font-weight-light" style={{"line-height":"1.6"}}>Jei
                            planuojate atlikti mokėjimo pavedimą už Placental kremą, pavedimo paskirtyje nurodykite
                            užsakymo numerį arba kremo pavadinimą ir tai yra viskas, ko mums reikia! Pavedimą atlikite
                            naudodami šiuos rekvizitus:
                            <ul style={{"list-style": "none"}} className={"mt-4 pl-0"}>
                                <h5 className={'font-weight-light'}>Placental</h5>
                                <li>Įmonės kodas 305163972</li>
                                <li>Adresas: Algirdo g. 46-309, LT-03209 Vilnius</li>
                                <li>AB SEB Bankas</li>
                                <li>Atsiskaitomoji sąskaita: LT74 7044 0600 0829 1102</li>
                                <li>Mokėjimo paskirtyje galite nurodyti sąskaitos numerį, užsakymo numerį arba produkto
                                    pavadinimą, pavyzdžiui "už Placental kremą"</li>
                            </ul>
                        </h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CompanyGoods)