import React from "react";
import {getUrls} from './Config';
import axios from "axios";

class ConfirmRegistration extends React.Component {

    constructor(props){
        super(props);
        this.state={
            token: false,
            email: null,
            success: false,
            message: null,
            spinner: true,
            urls: getUrls(),
            noTokenMsg: "Neturite laukiančių patvirtinimų.",
            successfulConfirmationMsg: "Jūsų registracija patvirtinta. Dėkojame, kad prisijungėte! Parvirtintas el. paštas: ",
            pendingConfirmationMsg: "Jūsų registracija tvirtinama. Prašome palaukti... "
        }
    }

    disableSpinner = () => {
        this.setState({
            spinner: false
        });
    }

    getToken = () => {
        let tokenObj = new URLSearchParams(this.props.location.search);
        let token = tokenObj.get('token');
        let result = token ? token : false;

        return result;
    }

    errorMessageHandler = () => {
        this.setState({
            message: this.state.noTokenMsg
        });

        this.disableSpinner();
    }

    successMessageHandler = (email) => {
        this.setState({
            message: this.state.successfulConfirmationMsg + email,
            success: true
        });

        this.disableSpinner();
    }

    initMessageHandler = () => {
        let token = this.getToken();

        if(!token){
            this.errorMessageHandler();

            return;
        }
        this.setState({
            message: this.state.pendingConfirmationMsg
        });
    }

    confirmRegistration = (token) => {
        if(!token){
            this.errorMessageHandler();

            return;
        }
        axios.get(this.state.urls.environment.development + this.state.urls.confirm + token).then(
            (res) => {
                if(res.data.registrationConfirmed){
                    this.successMessageHandler(res.data.email);
                } else {
                    this.errorMessageHandler();
                }
            }
        ).catch(
            (err) => {
                this.errorMessageHandler();
                console.log(err);
            }
        );
    };

    componentDidMount(){
        this.confirmRegistration(this.getToken());
        this.initMessageHandler();
    }

    render() {
        let token = this.getToken();
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12'}>
                        <h4 className={'font-weight-light'}>Registracijos patvirtinimas</h4>
                        <p className={'mt-5'}>
                            {this.state.message}
                            <span className={this.state.spinner ? '' : 'd-none'}>
                                <fa className="fa fa-sync fa-spin"></fa>
                            </span>
                        </p>

                        <h1 className={this.state.success ? 'text-success' : 'd-none text-success'}>
                            <fa className="fa fa-check-circle"></fa>
                        </h1>

                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmRegistration