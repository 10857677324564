import React from "react";

class CheckoutProgress extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            inProgress: true
        }
    }

    componentDidMount(){}

    render() {
        return (
            <div className={"mt-5 mb-5 pt-5 pb-5"}>
                <span className="loader mb-3"></span>
                <h3 className="font-weight-light">Ruošiame Jūsų užsakymą</h3>
                <p className={"pt-3"}>Jūsų užsakymas apdorojamas, prašome palaukti...
                    <fa className={this.state.inProgress ? 'fa fa-sync fa-spin ml-2' : 'd-none'}></fa>
                </p>
            </div>
        )
    }
}

export default CheckoutProgress