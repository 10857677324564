export const productData = [
    {
        "cream": {
            "name": "Face Cream",
            "price": 120
        },
        "serum": {
            "name": "Face Serum",
            "price": 140
        },
        "set": {
            "name": "Face Cream and Serum",
            "price": 199
        },
    },
];