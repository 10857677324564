import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class SerumComposition extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'} to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'} to={'/cream-composition'}>Placental serumo sudėtis</NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-12'}>
                        <h3 className="font-weight-light mb-2">Placental serumo sudėtis</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Apie Placental serumą</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Jūsų rankose išskirtinis produktas! Veido serumas su placentos ekstraktu,  hialurono rūgštimi
                            ir dviejų rūšių peptidais. Ši priemonė puikiai tinka visų odos tipų grožio ritualams.
                            Placentos baltymai - ypatingas ingredientas, kuris gali veikti kaip maitinanti, drėkinanti,
                            skaistinanti ir raminanti priemonė Jūsų odai. Placental veikdo serumas inovatyvių
                            ingredientų kokteilis jūsų veido odai. Šis produktas praturtintas ne tik placentos baltymų
                            ekstraktų,tačiau ir hialurono rūgstimi, kuri žinoma, kaip vienas geriausių odos drėkiklių,
                            taip pat heksapeptidais ir pentapeptidais, kurie puikiai tinka brandžios odos priežiūrai.
                        </h6>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Ši priemonė - puikus būdas palepinti savo odą.
                            Veido serumas su placentos ekstraktu, peptidais ir hialurono rügstimi. Visiems odos tipams.
                            Šio hialurono rūgšties serumo funkcija - prisidėti prie kasdienio Jūsų odos grąžinimo
                            ritualo.
                        </h6>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Produkto sudėtyje yra drėkiklių, kurie gali palaikyti odos drėgmės balansą. Veido
                            serumas praturtintas odą puoselėjančiais ingredientais, tokiais kaip placentos ekstraktas
                            bei peptidai, todėl puikiai tinka visų tipų odos priežiūrai.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Aktyvieji ingredientai</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Placentos ekstraktas, peptidai, hialurono rūgštis.
                        </h6>
                        <h4 className={'font-weight-light mt-5 mb-3'}>Naudojimas</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Ryte ir (ar) vakare tepti švarią veido, kaklo bei dekoltė odą. Švelniai įmasažuoti ir
                            palaukti, kol įsigers. Po to rekomenduojama naudoti veido krema ar emulsija.
                        </h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(SerumComposition)