import React, {Component, createContext} from 'react';

import 'izitoast/dist/css/iziToast.min.css';
import iziToast from 'izitoast/dist/js/iziToast.min';
import returnGoods from "../pages/ReturnGoods";

export const AppContext = createContext();

class CartContextProvider extends Component {
    state = {
        cartItemsCount: 0,
    }

    resetState = () => {
        const initialState = {
            cartItemsCount: 0,
        }

        this.setState(
            initialState
        );
    }

    infoMessageToast = (text) => {
        iziToast.show({
            id: null,
            class: '',
            title: '',
            titleColor: '',
            titleSize: '',
            titleLineHeight: '',
            message: 'Placental Face Cream ' + text,
            messageColor: '#00c9a7',
            messageSize: '12',
            messageLineHeight: '',
            backgroundColor: '',
            theme: '', // dark, light
            color: '#E9FAF6', // blue, red, green, yellow
            icon: 'fa fa-cart-arrow-down',
            iconText: '',
            iconColor: '#00c9a7',
            iconUrl: null,
            image: '',
            imageWidth: 50,
            maxWidth: null,
            zindex: null,
            layout: 1,
            balloon: false,
            close: true,
            closeOnEscape: false,
            closeOnClick: false,
            displayMode: 0, // once, replace
            position: 'topLeft', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            target: '',
            targetFirst: true,
            timeout: 3000,
            rtl: false,
            animateInside: true,
            drag: true,
            pauseOnHover: true,
            resetOnHover: false,
            progressBar: false,
            progressBarColor: '#00c9a7',
            progressBarEasing: 'linear',
            overlay: false,
            overlayClose: false,
            overlayColor: 'rgba(0, 0, 0, 0.6)',
            transitionIn: 'fadeInUp',
            transitionOut: 'fadeOut',
            transitionInMobile: 'fadeInUp',
            transitionOutMobile: 'fadeOutDown',
            buttons: {},
            inputs: {},
            onOpening: function () {},
            onOpened: function () {},
            onClosing: function () {},
            onClosed: function () {}
        });

    };

    updateItemCounter = (product, action) => {
        let cartItemsCount = this.state.cartItemsCount;

        let increaseNumber = 1;
        let itemCountKey = "count";
        let message = "pridėtas į krepšelį.";

        let cartItemsStorageObject = localStorage.getItem(
            'cartItems'
        );
        let cartItemsStorageObjectToJson = JSON.parse(
            cartItemsStorageObject
        );

        let cartItemsObject  = {
            "cream": 0,
            "serum": 0,
            "set": 0,
            "count": 0
        }

        if (cartItemsStorageObject !== null && cartItemsStorageObject !== "NaN") {
            cartItemsObject = cartItemsStorageObjectToJson;
        }

        if(action === "remove"){
            message = "išimtas iš krepšelio.";
        }

        if (action === "remove" && cartItemsObject[product] !== 0){
            increaseNumber = -1;
        }

        if (action === "remove" && cartItemsObject[product] === 0){
            increaseNumber = 0;
        }

        cartItemsObject[product] = cartItemsObject[product] + increaseNumber;

        this.infoMessageToast(message);

        if (action === "add"){
            cartItemsCount = cartItemsCount + 1;
        }

        if (action === "remove"){
            if(cartItemsCount !== 0){
                cartItemsCount = cartItemsCount - 1;
            }
        }

        cartItemsObject[itemCountKey] = cartItemsCount

        localStorage.setItem(
            'cartItems',
            JSON.stringify(cartItemsObject)
        );

        if(cartItemsCount === 0){
            localStorage.removeItem(
                "cartItems"
            );
        }

        this.setState({
            cartItemsCount: cartItemsCount
        });
    }

    contextPersistence = (persistedState) => {
        this.setState(
            persistedState
        );
    }

    componentDidMount() {
        const persistedAppContext = localStorage.getItem("appContext");

        if(persistedAppContext != null){
            this.contextPersistence(JSON.parse(persistedAppContext));
        }else{
            this.resetState();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        localStorage.setItem("appContext", JSON.stringify(this.state));
    }

    render() {
        return (
            <AppContext.Provider value={{
                ...this.state,
                updateItemCounter: (item, action) => this.updateItemCounter(item, action)
            }}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default CartContextProvider;