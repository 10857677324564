import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class Black extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/black'}>Placental Goes Black</NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4 text-center'}>
                    <div className={'col-sm-2'}>&nbsp;</div>
                    <div className={'col-sm-8'}>
                        <div className={"text-right text-danger"}>
                            <span className="badge badge-danger">Coming soon!</span>
                        </div>
                        <h1 className="font-weight-light display-4 ">
                            <span className={"placental-title display-3"}>Placental </span>
                            goes <span className={"font-weight-bold text-white pr-3 pl-3"}
                                       style={{"background-color":"black"}}>Black</span></h1>

                        <h3 className={'font-weight-light mt-1 mb-5'}>Ypatingoms progoms - ypatingas <h1
                            className={"placental-title d-inline-block mr-2 ml-2"}>Placental</h1></h3>

                        <h4 className="font-weight-normal mb-5 mt-5" style={{"line-height":"1.6"}}>
                            Ypatingomis progomis dovanokite ypatingą <h1
                            className={"placental-title d-inline-block mr-2 ml-2"}>
                            Placental</h1> kremą! <br/><h1 className={"placental-title d-inline-block mr-2 ml-2"}>
                            Placental</h1>goes &nbsp;
                            <span className={"font-weight-bold text-white pr-2 pl-2"}
                                  style={{"background-color":"black", "padding-top":"4px",
                            "padding-bottom":"4px"}}>Black</span> visada <span className={"font-weight-bold " +
                        "text-white pr-2 pl-2"} style={{"background-color":"black", "padding-top":"4px",
                            "padding-bottom":"4px"}}> Limited Edition</span> puikiai tinkantis nustebinti.
                        </h4>

                        <h6 className="font-weight-normal" style={{"line-height":"1.6"}}>
                            Sekite mus <a href="https://www.facebook.com/Placental-1589459521318203/"
                                          style={{"font-size":"16px"}} target={"_blank"}>Facebook</a> paskyroje arba
                            &nbsp;<a href="https://www.placental.lt" style={{"font-size":"16px"}} target={"_blank"}>
                                 www.placental.lt</a> ir įsigykite visada riboto leidimo
                            <h4 className={"placental-title d-inline-block mr-2 ml-1"}>Placental
                        </h4>Goes Black.</h6><small className={"text-muted"}>Dėl papildomos informacijos kreipkitės el.
                        paštu arba kitais, Jums patogiais kanalais.</small>
                    </div>
                    <div className={'col-sm-2'}>&nbsp;</div>
                </div>
            </div>
        )
    }
}

export default withRouter(Black)