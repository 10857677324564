import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import placental_logo_secondary from "../images/placental_bg_gray.png";

class Footer extends React.Component{
    render() {
        return (
            <footer className={'text-muted mt-5'} style={{borderTop: '1px solid #ddd'}}>
                <div className={'row pt-4 pr-4 pl-4'}>
                    <div className={'col-12'}>
                        <img className={'mr-1'}
                             src={placental_logo_secondary}
                             alt={'Placental logo'}
                             style={{width: '18px', height: '18px', marginTop: '-3px'}}/>
                        <span className="fa fa-angle-right fa-lg text-muted"></span>
                        <span className={'ml-1'}
                              style={{fontSize: '13px'}}>Placental</span>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-sm-3'}>
                        <p className={'text-dark'}>Informacija</p>
                        <NavLink to={'/company-details'} className={'d-block pb-2 text-muted'}>Rekvizitai</NavLink>
                        <NavLink to={'/delivery'} className={'d-block pb-2 text-muted'}>Prekių pristatymas</NavLink>
                        <NavLink to={'/return-goods'} className={'d-block pb-2 text-muted'}>Prekių grąžinimas</NavLink>
                        <NavLink to={'/faq'} className={'d-block pb-2 text-muted'}>DUK</NavLink>
                        <NavLink to={'/payments'} className={'d-block pb-2 text-muted'}>Mokėjimai</NavLink>

                        <p className={'text-dark mt-3'}>Naudingos nuorodos</p>
                        <a target={"_blank"}
                           href={'https://e-seimas.lrs.lt/portal/legalAct/lt/TAD/TAIS.148061'}
                           className={'d-block pb-2 text-muted'}>Grąžinimas</a>
                    </div>
                    <div className={'col-sm-3'}>
                        <p className={'text-dark'}>Parduotuvė</p>
                        <NavLink to={'/cart'} className={'d-block pb-2 text-muted'}>Krepšelis</NavLink>
                        <NavLink to={'/profile'} className={'d-block pb-2 text-muted'}>Vartotojo zona</NavLink>
                        <NavLink to={'/orders'} className={'d-block pb-2 text-muted'}>Užsakymai</NavLink>

                        <p className={'text-dark mt-3'}>Siuntų sekimas</p>
                        <a target={"_blank"} href={'https://www.dpd.com/lt/lt/sekti-siunta/'}
                           className={'d-block pb-2 text-muted'}>DPD</a>
                        <a target={"_blank"} href={'https://www.omniva.lt/verslo/siuntos_sekimas'}
                           className={'d-block pb-2 text-muted'}>Omniva</a>
                        <a target={"_blank"} href={'https://www.post.lt/siuntu-sekimas'}
                           className={'d-block pb-2 text-muted'}>Lietuvos paštas</a>
                    </div>
                    <div className={'col-sm-3'}>
                        <p className={'text-dark'}>Naudinga</p>
                        <NavLink to={'/help'} className={'d-block pb-2 text-muted'}>Pagalba</NavLink>
                        <NavLink to={'/wholesale'} className={'d-block pb-2 text-muted'}>Didmeninė prekyba</NavLink>
                        <NavLink to={'/black'} className={'d-block pb-2 text-muted'}>Placental goes Black</NavLink>

                        <p className={'text-dark mt-3'}>Dokumentai</p>
                        <NavLink to={'/certificates'} className={'d-block pb-2 text-muted'}>Sertifikatai</NavLink>

                    </div>

                    <div className={'col-sm-3'}>
                        <p className={'text-dark'}>Techniniai duomenys</p>
                        <NavLink to={'/cream-composition'}
                                 className={'d-block pb-2 text-muted'}>
                            Placental kremo sudėtis
                        </NavLink>
                        <NavLink to={'/bio-proteinol-pf'}
                                 className={'d-block pb-2 text-muted'}>Bio-Proteinol PF</NavLink>

                        <p className={'text-dark mt-3'}>Bendraukime</p>
                        <a href="https://www.facebook.com/Placental-1589459521318203/"
                           className={'d-block pb-2 text-muted'}
                           target="_blank">Facebook</a>
                        <NavLink to={'/contacts'}
                                 className={'d-block pb-2 text-muted'}>Kontaktai</NavLink>
                    </div>
                </div>
                <div className={"m-0 p-0"}
                     style={{borderTop: '1px solid #ddd'}}></div>
                <div className={'row p-4'} >
                    <div className={'col-sm-4 p-0'}>
                        <a href="https://www.facebook.com/Placental-1589459521318203/"
                           target="_blank">
                            <span className={'la la-facebook la-2x'}></span>
                        </a>
                    </div>
                    <div className={'col-sm-8 p-0'}>
                        <p className={'d-block text-right'}>&copy; Placental. Visos teisės saugomos | All rights
                            reserved.
                            <span className="flag-icon fl flag-icon-lt ml-5 mr-2"
                                  style={{fontSize: '12px'}}></span>
                            Lietuva | Lithuania
                        </p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default withRouter(Footer)