import React from "react";
import Swal from "sweetalert2";
import Carousel from "./home/Carousel";
import Masonry from "./home/Masonry";

class Home extends React.Component{
    constructor(props){
        super(props);
        this.state={
            show: false,
            paymentReceived: false
        }
    }

    checkPayment = () => {
        if(this.props.location.state != null){
            if (typeof(this.props.location.state) !== 'undefined' &&
                typeof(this.props.location.state.paymentReceived) !== 'undefined' &&
                this.props.location.state.paymentReceived === true
            ){
                this.setState({paymentReceived: this.props.location.state.paymentReceived},
                    () => {
                        if (this.state.paymentReceived){
                            this.paymentConfirmationMessage();
                            window.history.pushState({paymentReceived: false}, '', '/');
                        }
                    });
            }
        }
    }

    paymentConfirmationMessage = () => {
        let title = "Užsakymas";
        let paymentMethod = localStorage.getItem("paymentMethod");

        if (paymentMethod === "INTEGRATED") {
            title = "Mokėjimas"
        }

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: title + ' gautas!',
            text: 'Dėkojame už Jūsų užsakymą!',
            icon: 'success',
            confirmButton: 'btn btn-success',
            confirmButtonText: 'Uždaryti',
        })
    };

    addToCartHome = (item) => {
        this.props.add_to_cart(item);
    }

    componentDidMount(){
        this.checkPayment();
    }

    render() {
        return (
                <div>
                    <Carousel />
                    <Masonry add_to_cart={(item) => this.addToCartHome(item)} />
                </div>
        )
    }

}

export default Home