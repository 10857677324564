import React from "react";
import {Redirect, withRouter} from "react-router-dom";
import {errorMessage, getCart, getJwt} from "./Helper";
import ProceedPayment from './ProceedPayment';
import OrderSummary from './OrderSummary';
import {getUrls} from "./Config";
import {productData} from "./data/ProductData";

import axios from "axios";
import CheckoutProcess from "./checkout/CheckoutProcess";

class Payment extends React.Component{x
    constructor(props){
        super(props);
        this.state={
            opay: null,
            order_nr: null,
            userId: false,
            productId: null,
            urls: getUrls(),
            paymentMethod: "INTEGRATED",
            paymentMethodLabel: "Atsiskaitymas per svetainę",
            products: {},
            proceedPayment: false,
            orderSummary: true,
            progress: false,
            success: false,
            error: false

        }
    }


    orderInitiated = (orderNr) => {
        localStorage.setItem("orderInitiated", 'true');
        localStorage.setItem("orderNr", orderNr);
    }

    getProductId = () => {
        axios.get(
            this.state.urls.environment.development +
            this.state.urls.apiVersion +
            this.state.urls.order.product
        ).then((response) => {
            this.setState({
                productId: response.data
            });
        }).catch(
            (err) => {
                console.log(err);
            }
        );
    };

    generateRandomString = () => {
        return Math.random().toString(36).substring(7);
    }

     getProducts = async () => {
        return await axios.get(
            this.state.urls.environment.development + this.state.urls.apiVersion + this.state.urls.order.product
        ).then((response) => {
            return response.data;
        }).catch(
            (err) => {
                console.log(err);
            }
        );
    }

    storeAnonymousUser = async () => {
        let user = JSON.parse(
            localStorage.getItem('anonymous-user')
        );

        user.password = this.generateRandomString();
        user.username = user.name.toLowerCase() + "_anonymous_" + this.generateRandomNumber();
        user.anonymous = true;
        user.lastName = user.last_name;
        user.postalCode = user.postal_code;
        user.street = user.address;

        const anonymousUser = await axios.post(
            this.state.urls.environment.development +
            this.state.urls.apiVersion +
            this.state.urls.user.register,
            user
        )

        return anonymousUser.data.id;
    }

    getUserId = async () => {
        let jwt = getJwt();

        const user = await axios.get(
            this.state.urls.environment.development +
            this.state.urls.apiVersion +
            this.state.urls.user.profile, {
            'headers': {
                'Authorization': jwt
            }
        })

        return user.data.id
    }

    getProductPrice = (product) => {
        return productData[0][""+ product +""].price
    }

    buildOrder = async (userID, orderNumber) => {
        let products = await this.getProducts();
        let items = JSON.parse(
            localStorage.getItem("cartItems")
        );

        delete items.count;

        let productsIds = {};
        let order = [];

        for (const [key, value] of Object.entries(products)) {
            productsIds[""+products[key].description+""] = products[key].id;
        }

        for (const [key, value] of Object.entries(items)) {
            let orderDetails = {
                userId: userID,
                productId: null,
                uuid: this.generateRandomNumber(),
                status: 1,
                quantity: null,
                price: null,
                paid: 0,
                paymentMethod: this.state.paymentMethod,
                orderNr: orderNumber,
            }

            if(value > 0){
                orderDetails.productId = productsIds[""+key+""];
                orderDetails.quantity = value;
                orderDetails.price = this.getProductPrice(key);
                order.push(orderDetails);
            }
        }

        return order;
    }

    placeOrder = async () => {
        this.orderInitiated(this.state.order_nr);

        const order = await this.buildOrder(this.state.userId, this.state.order_nr);

        return axios.post(
            this.state.urls.environment.development +
                this.state.urls.apiVersion +
                this.state.urls.order.post, order)
            .catch(
                (err) => {
                console.log(err);

                localStorage.removeItem(
                    'orderInitiated'
                );

                localStorage.removeItem(
                    'orderNr'
                );

                this.setState({
                    error: true,
                    success:false,
                    progress: false,
                    orderSummary: false
                });

            }
        );
    }

    getPaymentMethod = () => {
        const paymentMethod = localStorage.getItem(
            "paymentMethod"
        );

        this.setState({
            paymentMethod: paymentMethod
        });

        this.mapPaymentMethodNaming(
            localStorage.getItem("paymentMethod")
        );

        return paymentMethod;
    }

    mapPaymentMethodNaming = (paymentMethod) => {
        switch(paymentMethod) {
            case "INTEGRATED":
                this.setState({
                    paymentMethodLabel: "Atsiskaitymas per svetainę"
                });
                break;
            case "BANKING":
                this.setState({
                    paymentMethodLabel: "El. bankininkystė"
                });
                break;
            case "CASH":
                this.setState({
                    paymentMethodLabel: "Grynieji pinigai"
                });
                break;
            default:
                this.setState({
                    paymentMethodLabel: "Atsiskaitymas per svetainę"
                });
        }
    }

    generateRandomNumber = () => {
        return Math.floor(100000 + Math.random() * 900000);
    }

    generateOrderNumber = async () => {
        return (await axios.get(
            this.state.urls.environment.development +
            this.state.urls.apiVersion +
            this.state.urls.order.generateOrderNumber
        )).data;
    }

    processOrder = async () => {
        this.getProductId();
        this.getPaymentMethod();

        let orderNr = await this.generateOrderNumber();

        this.setState({
            order_nr: orderNr,
            progress: true,
            orderSummary: false
        });

        let anonymousUserObject = JSON.parse(
            localStorage.getItem("anonymous-user")
        );
        let jwt = getJwt();
        let userID = null;

        if (anonymousUserObject != null && jwt === null) {
            const anonymousUserCreatedID = await this.storeAnonymousUser();

            anonymousUserObject['id'] = anonymousUserCreatedID;

            localStorage.setItem(
                'anonymous-user',
                JSON.stringify(anonymousUserObject)
            );

            userID = anonymousUserCreatedID;
        } else {
            userID = await this.getUserId();
        }

        this.setState({
            userId: userID
        });

        await this.placeOrder();

        this.setState({
            error: false,
            success:true,
            progress: false,
            orderSummary: false
        });

        localStorage.removeItem(
            'orderInitiated'
        );

        localStorage.removeItem(
            'anonymous-user'
        );

        localStorage.removeItem(
            'orderNr'
        );

        localStorage.setItem(
            "cartItems", JSON.stringify(
                {
                    "cream":0,"serum":0,"set":0, "count":0
                })
        );

    }


    componentDidMount(){
        this.buildOrder().then(() => {});
        this.props.clearCart();
    };

    render() {
        if (this.props.price * JSON.parse(
            getCart()).count <= 0 &&
            localStorage.getItem('paymentInitiated') === null ||
            localStorage.getItem('userDataValid') === 'false') {
            return <Redirect to={{
                pathname: '/cart'
            }} />
        }
        return (
            <div className={"content"}>

                {this.state.proceedPayment && (
                    <ProceedPayment
                        clearCart = {() => this.props.clearCart()}
                        orderNr = {this.state.order_nr}
                    />
                )}

                {this.state.orderSummary && (
                    <OrderSummary
                        paymentMethod = {this.state.paymentMethodLabel}
                        processOrder = {() => this.processOrder() }
                    />
                )}

                <CheckoutProcess
                    progress = {this.state.progress}
                    success = {this.state.success}
                    error = {this.state.error}
                    clearCart = { () => this.props.clearCart()}
                />

                {this.state.orderSummary && (
                    <div className={'row'}>
                        <div className={'col-12 p-4'}>
                            <a href="#"
                               onClick={() => this.processOrder()}
                               className={'btn btn-primary mt-5 ml-3'}>
                                Tvirtinti
                            </a>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(Payment)